import React, { Component } from "react";
import { RecognitionSection } from "../HealthTabV2/styles";
import { DashboardLayout } from "../CompanyDashBoardV2/styles";
import { HeaderContainer } from "../HealthTab/styles";
import EmployeesOfTheMonth from "../EmployeesOfTheMonth/index";
import {
  fetchRecognitionTabApi,
  getRecognitionForAllDepartments,
  getRecognitionForAllLocations,
  setSelectedYear,
} from "../../redux/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Waiting from "../Waiting";
import _ from "lodash";
import isEmpty from "lodash/isEmpty";
import RecognitionActivity from "./RecognitionActivity";
import RecognitionCustomDropDown from "../Experience/AnnualSurvey/CustomDropDown/recognitionDropdown";
import CompanyAnalytics from "./CompanyAnalytics";
import { ComparisonStyledModal, ConfirmStyledModal, HeaderTitle } from "./styles";
import {
  RowV2,
  TotalCountNewV2,
  NewImgContainerV2,
  RowV1,
  FourCardWrapper,
  ToggleButton,
  WrapperYear,
  YearDataComparison
} from "./styles";
import { commaSeperator } from "../../utils/methods";
import { getRecognitionActivityDetails, fetchEmployeeSeprateRecognitionMonthly } from "../../redux/actions";
import { 
  Modal, OverlayTrigger, Popover } from "react-bootstrap";
import Anniversaries from "../Anniversaries";
import {
  GetAnniversaryListMonthwise,
  GetBirthdayAndAnniversaryCardStatus,
  GetBirthdayListMonthwise,
  GetRecognitionComparison,
  PutBirthdayAndAnniversaryCardToggle
} from "../../redux/constants/apiConstants";
import { BASE_URL } from "../../utils/config";
import { ComparisonIcon, ManageAnniversaryIcon } from "./Icons";
import HoverButton from "../common/HoverButton";
import { CustomTitle, SeeAllStyledModal } from "../AdminTeamCreation/styles";
import ExcelJS from 'exceljs/dist/exceljs';
import moment from "moment";
import { toast } from "react-toastify";
import { ImageUrl } from "../../utils/constants";
import LazyImage from "../common/LazyImage/LazyImage";
import YearDropdown from "../common/YearDropdown/YearDropdown";
import {BuddyTab} from '../PeopleHomeV2/styles';
import {StyledTitle} from '../EngagementSectionV2/CompanyInitiative/styles';
import {NewActivityDropdownCommon} from '../common/CommonDropDown/style';
import { Dropdown } from "react-bootstrap";
import {
  closeIcon
} from "../../utils/icons";

class Recognition extends Component {
  constructor(props) {
    super(props);
    this.exportToExcel = this.exportToExcel.bind(this);
    this.state = {
      companyId: props.companyInfo.id,
      currentTab: "",
      birthdayList: {},
      anniversaryList: {},
      isManageAnniversaryModal: false,
      manageAnniversary: { isBirthday: 0, isAnniversary: 0 },
      confirmAnniversary: { showModal: false, type: "" },
      isComparisonModal:false,
      recognitionComparisonData:{},
      comparisonFromYear:2022,
      comparisonToYear:2021,
      activeTab: props.history?.location?.state?.currentTab?props.history?.location?.state?.currentTab: 0,
      menuIsOpen: false,
      label:props.history?.location?.state?.label?props.history?.location?.state?.label: "Analysis"
    };
    this.dropdownRef = React.createRef();
  }
  componentDidMount() {
    const {
      getRecognitionForAllDepartments,
      getRecognitionForAllLocations,
      fetchEmployeeSeprateRecognitionMonthly
    } = this.props;
    const { companyId } = this.state;
    let currentYear = new Date().getFullYear();
    this.props.fetchRecognition(this.state.companyId, currentYear);
    // fetchRecognitionActivityDetails(companyId, currentYear);
    getRecognitionForAllDepartments(companyId, currentYear);
    getRecognitionForAllLocations(companyId, currentYear);
    fetchEmployeeSeprateRecognitionMonthly(companyId, currentYear);
    this.fetchBirthdayList(companyId);
    this.fetchAnniversaryList(companyId);
    this.fetchAnniversaryCardStatus(companyId);

    let date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    this.handleComparisonYear(new Date().getFullYear(),date.getFullYear());
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ menuIsOpen: false });
    }
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuIsOpen: !prevState.menuIsOpen
    }));
  };

  fetchBirthdayList = async (companyId, locationId, departmentId) => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    // let payload = {location_id:locationId,department_id:departmentId}
    let url = BASE_URL + GetBirthdayListMonthwise + "?company_id=" + companyId;
    if (departmentId && locationId) {
      url =
        url + "&department_id=" + departmentId + "&location_id=" + locationId;
    } else if (departmentId) {
      url = url + "&department_id=" + departmentId;
    } else if (locationId) {
      url = url + "&location_id=" + locationId;
    }
    await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } }).then(
      (res) => {
        res.json().then((data) => {
          this.setState({ birthdayList: data.data });
        }).catch((error)=>{
          toast.error(error.message);
        });
      }
    ).catch((error)=>{
      toast.error(error.message);
    });
  };

  fetchAnniversaryList = async (
    companyId,
    locationId = null,
    departmentId = null
  ) => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    // let payload = {location_id:locationId,department_id:departmentId}
    let url =
      BASE_URL + GetAnniversaryListMonthwise + "?company_id=" + companyId;
    if (departmentId && locationId) {
      url =
        url + "&department_id=" + departmentId + "&location_id=" + locationId;
    } else if (departmentId) {
      url = url + "&department_id=" + departmentId;
    } else if (locationId) {
      url = url + "&location_id=" + locationId;
    }
    await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } }).then(
      (res) => {
        res.json().then((data) => {
          this.setState({ anniversaryList: data.data });
        }).catch((error)=>{
          toast.error(error.message);
        });
      }
    ).catch((error)=>{
      toast.error(error.message);
    });
  };

  fetchAnniversaryCardStatus = async(companyId)=>{
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url =
    BASE_URL + GetBirthdayAndAnniversaryCardStatus + companyId;
    await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } }).then(
      (res) => {
        res.json().then((data) => {
          this.setState({ manageAnniversary: {isBirthday:data.data[0].show_birthday_card,isAnniversary:data.data[0].show_work_anniversary_card} });
        }).catch((error)=>{
          toast.error(error.message);
        });
      }
    ).catch((error)=>{
      toast.error(error.message);
    });
  };


  updateAnniversaryCardStatus = async(e)=>{
    const { companyId, manageAnniversary } = this.state;
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url =
    BASE_URL + PutBirthdayAndAnniversaryCardToggle;
    const payload = {company_id:companyId,show_birthday_card: e.target.name ==="isBirthday" && manageAnniversary.isBirthday ? 1 : 0,show_work_anniversary_card: e.target.name ==="isAnniversary" && manageAnniversary.isAnniversary ? 1 : 0,}
    await fetch(url, { method: "PUT", headers: { AUTHTOKEN: AuthToken }, body:JSON.stringify(payload) }).then(
      (res) => {
        res.json().then(() => {
          this.setState({ manageAnniversary: {...manageAnniversary, [e.target.name]: e.target.checked,} });
        }).catch((error)=>{
          toast.error(error.message);
        });
      }
    ).catch((error)=>{
      toast.error(error.message);
    });
    
  }



  exportToExcel(showTeams) {

    let data = showTeams ? this.state.birthdayList : this.state.anniversaryList;

    const workbook = new ExcelJS.Workbook();
    const sheetNames = Object.keys(data);
   
    sheetNames.forEach((month) => {
      const sheetData = data[month].users;
      const worksheet = workbook.addWorksheet(month);
   
      worksheet.columns = [
        { header: 'Name', key: 'name', width: 20 },
        { header: showTeams ? 'Birth Date' : 'Work Anniversary', key: showTeams ? 'birthday' : 'work_anniversary', width: 20, style: { numFmt: 'DD-MMMM-YYYY' }, },
        { header: 'Department', key: 'department', width: 15 },
        { header: 'City', key: 'city', width: 15 },
        { header: 'Gender', key: 'gender', width: 10 },
      ];
   
      sheetData.forEach((user) => {
        const dateKey = showTeams ? 'birthday' : 'work_anniversary';
        const formattedDate = moment(user[dateKey]).format('DD-MMMM-YYYY');
        const userData = {
          ...user,
          [dateKey]: formattedDate,
        };
        worksheet.addRow(userData);
      });
    });
     
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new window.Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = showTeams ? 'birthdaylist.xlsx' : 'anniversarylist.xlsx';
  
      // Programmatically trigger the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }


  updateDropdown = (year) => {
    const {
      setSelectedYear,
      fetchRecognitionActivityDetails,
      getRecognitionForAllDepartments,
      getRecognitionForAllLocations,
    } = this.props;
    const { companyId } = this.state;
    setSelectedYear(year);
    this.props.fetchRecognition(this.state.companyId, year);
    fetchRecognitionActivityDetails(companyId, year);
    getRecognitionForAllDepartments(companyId, year);
    getRecognitionForAllLocations(companyId, year);
  };

  getActiveTab = (tab) => {
    this.setState({
      currentTab: tab,
    });
  };

  showRecognitionDetails = (recognitionDetails) => {
    const { getRecognitionActivityDetails } = this.props;
    const EngagementEmployeeData = [
      {
        title: "Total Social Posts",
        value: "1",
        img: "/images/SocialBox.svg",
        count: getRecognitionActivityDetails.social_posts,
        totalCount: "Posts",
        status: false,
        color: "#69C2FF",
        borderRadius: "11px",
        eligibleUsers: false,
        width: recognitionDetails.activity_challenge_percentage,
        background: "none",
        text: "Total posts on the social feed by all users.",
      },
      {
        title: "Recognition",
        titleNew: "Received",
        value: "2",
        img: "/images/TotalRecognitionimg.svg",
        count: getRecognitionActivityDetails.received_recognition,
        totalCount: "People Received",
        status: false,
        color: "#F6B479",
        lineimg: "/images/AdminEngagement/YellowRectangle.png",
        width: getRecognitionActivityDetails.received_recognition_percentage,
        per: "%",
        background: "rgba(246, 180, 121, 0.15)",
        text: "Users who have received peer recognition.",
      },
      {
        title: "Recognition",
        titleNew: "Given",
        value: "3",
        img: "/images/PeerRecognitionimg.svg",
        count: getRecognitionActivityDetails.gave_recognition,
        totalCount: "People Gave",
        status: false,
        color: "#FD7175",
        borderRadius: "11px",
        lineimg: "/images/AdminEngagement/RedRectangle2.png",
        width: getRecognitionActivityDetails.gave_recognition_percentage,
        per: "%",
        background: "rgba(253, 113, 117, 0.15)",
        text: "Users who sent peer recognition.",
      },
      {
        title: "Exemplifies Company",
        titleNew: "Value",
        value: "4",
        img: "/images/Exempifiesimg.svg",
        count: getRecognitionActivityDetails.exemplifies,
        totalCount: "People Exemplified",
        color: "#9FC989",
        lineimg: "/images/AdminEngagement/GreenRectangle2.png",
        pointsAvailable: false,
        width: getRecognitionActivityDetails.exemplifies_percentage,
        per: "%",
        background: "rgba(159, 201, 137, 0.15)",
        text: "Users who have received peer recognition that exemplifies company core values.",
      },
    ];

    const popover = (text) => (
      <Popover
        id="popover-basic"
        style={{
          width: "300px",
          padding: "9px 14px",
          background: "#FFFFFF",
          border: "1px solid #0D4270",
          boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
          borderRadius: "6px",
        }}
      >
        <div
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#0D4270",
            textAlign: "center",
          }}
        >
          {text}
        </div>
      </Popover>
    );

    return (
      <FourCardWrapper>
        {EngagementEmployeeData.map((details, index) => (
          <div className="card-wrap" key={index}>
            <RowV2>
              <LazyImage src={ImageUrl + details.img} />
              <div className="textdata">
                <div>
                  {details.title}
                  <br />
                  {details.titleNew}
                </div>
                <OverlayTrigger
                  placement="bottom"
                  overlay={popover(details.text)}
                >
                  <img className="info_img" src="data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M10%2020c5.523%200%2010-4.477%2010-10S15.523%200%2010%200%200%204.477%200%2010s4.477%2010%2010%2010Z%22%20fill%3D%22%23005C87%22%20fill-opacity%3D%22.3%22%2F%3E%3Cpath%20d%3D%22M9.692%2011.745v-.732c0-.216.016-.396.048-.54.032-.144.08-.272.144-.384a1.42%201.42%200%200%201%20.252-.336c.112-.112.248-.244.408-.396l.384-.36c.128-.128.244-.256.348-.384.104-.136.188-.276.252-.42.072-.152.108-.32.108-.504s-.036-.352-.108-.504a1.198%201.198%200%200%200-.264-.408%201.198%201.198%200%200%200-.408-.264%201.255%201.255%200%200%200-.492-.096c-.392%200-.712.124-.96.372-.24.248-.384.572-.432.972l-1.224-.12c.104-.696.392-1.24.864-1.632.48-.4%201.072-.6%201.776-.6.336%200%20.648.052.936.156.296.096.552.24.768.432.216.184.384.416.504.696.128.272.192.584.192.936%200%20.304-.044.572-.132.804a2.45%202.45%200%200%201-.372.636l-.72.744-.12.108c-.128.12-.232.224-.312.312-.072.08-.132.168-.18.264-.04.088-.068.188-.084.3a3.052%203.052%200%200%200-.024.42v.528H9.692Zm-.228%201.656c0-.216.076-.4.228-.552a.772.772%200%200%201%201.116%200%20.772.772%200%200%201%200%201.116.752.752%200%200%201-.552.228.792.792%200%200%201-.564-.228.792.792%200%200%201-.228-.564Z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E" />
                </OverlayTrigger>
              </div>
            </RowV2>
            <RowV1 padding={"15px 0px 0px 0px"}>
              <NewImgContainerV2
                width={details.width}
                background={details.background}
              >
                <LazyImage className="line-images" src={ImageUrl + details.lineimg} />
              </NewImgContainerV2>
            </RowV1>
            <RowV1
              paddingTop={"paddingTop"}
              font={"14px"}
              bgcolor={details.color}
            >
              <TotalCountNewV2
                marginTop={details.title == "Total Social Post" && "11px"}
                bgcolor={details.color}
              >
                <p
                  className={
                    details.title == "Total Social Post"
                      ? "big-startpoint"
                      : "start-point"
                  }
                >
                  {index !== 2
                    ? commaSeperator(details.count || 0)
                    : details.count}
                </p>
                <p className="start-text" style={{color:'#6499b3'}}>
                  {details.totalCount}
                  <span>{details.eligible_users ? "Eligible Users" : ""}</span>
                  <span>{details.status ? " Users" : ""}</span>
                  <span>{details.points_available ? "Available Pts" : ""}</span>
                </p>
              </TotalCountNewV2>
              <div className="percentdata">
                {details.width}
                {details.per}
              </div>
            </RowV1>
          </div>
        ))}
      </FourCardWrapper>
    );
  };

  redirectToAddCoreValues = () => {
    const { history } = this.props;
    history.push("/company/recognition/addcorevalues");
  };

  handleAnniversaryModal = () => {
    this.setState({
      isManageAnniversaryModal: !this.state.isManageAnniversaryModal,
    });
  };

  handleComparisonModal = () => {
    this.setState({
      isComparisonModal: !this.state.isComparisonModal,
    });
  };

  handleToggleBtn = async(e) => {
    // Prevents React from resetting its properties:
    e.persist();
    if (!e.target.checked) {
      this.setState({
        confirmAnniversary: {
          ...this.state.manageAnniversary,
          showModal: true,
          type: e.target.name,
        },
      });
    } else {
      const { companyId, manageAnniversary } = this.state;
      const AuthToken = localStorage.getItem("AUTH_TOKEN");
      let url =
      BASE_URL + PutBirthdayAndAnniversaryCardToggle;
      
      
      const payload = {company_id:companyId,show_birthday_card: e.target.name ==="isBirthday" ? 1 : manageAnniversary.isBirthday,show_work_anniversary_card: e.target.name ==="isAnniversary" ? 1 : manageAnniversary.isAnniversary};

      fetch(url, { method: "PUT", headers: { AUTHTOKEN: AuthToken, 'Content-Type': 'application/json' }, body:JSON.stringify(payload) }).then(
        (res) => {
          res.json().then((data) => {
            if(res.status === 200){
              this.setState({
                manageAnniversary: {
                  ...this.state.manageAnniversary,
                  [e.target.name]: 1,
                },
              })
            }else{
              toast.error(data.data.message);
            }
          }).catch((error)=>{
            toast.error(error.message);
          });
        }
      ).catch((error)=>{
        toast.error(error.message);
      });
      // this.updateAnniversaryCardStatus(e)
      // this.setState({
      //   manageAnniversary: {
      //     ...this.state.manageAnniversary,
      //     [e.target.name]: e.target.checked,
      //   },
      // });
    }
  };

  handleComparisonYear=async (fromYear, toYear)=>{
    const { companyId } = this.state;
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    fetch(BASE_URL + GetRecognitionComparison + "company_id=" + companyId + "&year_1="+fromYear + "&year_2=" + toYear, { method: "GET", headers: { AUTHTOKEN: AuthToken, 'Content-Type': 'application/json' }}).then(
      (res) => {
        res.json().then((data) => {
          if(res.status === 200){
            this.setState({recognitionComparisonData:data.data, comparisonFromYear:fromYear,comparisonToYear:toYear})
          }else{
            toast.error(data.data.message);
          }
        }).catch((error)=>{
          toast.error(error.message);
        });
      }
    ).catch((error)=>{
      toast.error(error.message);
    });

  }

  comparisonModal = () => {
    const { isComparisonModal,comparisonFromYear,comparisonToYear, recognitionComparisonData } = this.state;
    const EngagementEmployeeData = [
      {
        title: "Total Social Posts",
        value: "1",
        img: "/images/SocialBox.svg",
        fromCount: recognitionComparisonData[comparisonFromYear].social_posts,
        toCount: recognitionComparisonData[comparisonToYear].social_posts,
        totalCount: "Posts",
        color: "#85C0EA26",
        borderRadius: "11px",
        difference:recognitionComparisonData.social_posts_difference,
        differenceType: String(recognitionComparisonData.social_posts_difference).split("-").length > 1 ? 'minus' : 'plus',
      },
      {
        title: "Recognition Received",
        value: "2",
        img: "/images/TotalRecognitionimg.svg",
        fromCount: recognitionComparisonData[comparisonFromYear].received_recognition,
        toCount: recognitionComparisonData[comparisonToYear].received_recognition,
        totalCount: "People Received",
        color: "#DC94B526",
        width: getRecognitionActivityDetails.received_recognition_percentage,
        difference:recognitionComparisonData.received_recognition_difference,
        differenceType: recognitionComparisonData.received_recognition_difference.toString().split("-").length > 1 ? 'minus' : 'plus',
      },
      {
        title: "Recognition Given",
        value: "3",
        img: "/images/PeerRecognitionimg.svg",
        fromCount: recognitionComparisonData[comparisonFromYear].gave_recognition,
        toCount: recognitionComparisonData[comparisonToYear].gave_recognition,
        totalCount: "People Gave",
        color: "#76AB7826",
        difference:recognitionComparisonData.gave_recognition_difference,
        differenceType: recognitionComparisonData.gave_recognition_difference.toString().split("-").length > 1 ? 'minus' : 'plus',

      },
      {
        title: "Exemplifies Company Value",
        value: "4",
        img: "/images/Exempifiesimg.svg",
        fromCount: recognitionComparisonData[comparisonFromYear].exemplifies,
        toCount: recognitionComparisonData[comparisonToYear].exemplifies,
        totalCount: "People Exemplified",
        color: "#AF87C126",
        difference:recognitionComparisonData.exemplifies_difference,
        differenceType: recognitionComparisonData.exemplifies_difference.toString().split("-").length > 1 ? 'minus' : 'plus',
      },
    ];
    return (
      <ComparisonStyledModal
        show={isComparisonModal}
        onHide={this.handleComparisonModal}
        dialogClassName="modal-90w"
        style={{ overflowY:'hidden'}}
        border="1"
      >
        <Modal.Header style={{background:'white'}}>
          <StyledTitle style={{ color: "#005C87", width:"100%", fontSize: "18px", justifyContent:"flex-start", marginLeft: '30%', lineHeight:"24px", display:"flex" }}>
            Recognition Overview Comparison
            <div  onClick={this.handleComparisonModal} style={{display:"flex", margin:"auto 0 auto auto", cursor:"pointer"}}>
              {closeIcon()}
            </div>
          </StyledTitle>
        </Modal.Header>
        <Modal.Body style={{ padding:'24px' }}>
          <div style={{width:"100%", marginBottom:"16px"}}>
            <span style={{fontSize:'16px',lineHeight:'24px', color:"#005c87"}}>Select years to compare</span>
          </div>
          <WrapperYear style={{width:"100%", display:"flex"}}>
            <NewActivityDropdownCommon
              style={{width:"350px"}}
            >
              <Dropdown.Toggle>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",fontFamily:"Rubik-Medium"}}>
                  <div>{comparisonToYear}</div>
                  {this.props.yearList?.length>1?<div>
                    <img
                      alt="image"
                      src="/public/images/teamDownArrow.svg"
                    />
                  </div>:null}
                </div>
              </Dropdown.Toggle>
              {this.props.yearList?.length>1?<Dropdown.Menu>
                {this.props.yearList?.map((list, index) => (
                  comparisonFromYear>list&&
                  <Dropdown.Item
                    key={index}
                    onClick={() => this.handleComparisonYear(this.state.comparisonFromYear,list)}
                  >
                    {list}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>:null}
            </NewActivityDropdownCommon>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={28}
                height={28}
                viewBox="0 0 28 28"
                fill="none"
              >
                <mask
                  id="mask0_90_4019"
                  style={{
                    maskType: "alpha",
                  }}
                  maskUnits="userSpaceOnUse"
                  x={0}
                  y={0}
                  width={28}
                  height={28}
                >
                  <rect width={28} height={28} fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_90_4019)">
                  <path
                    d="M8 14H20"
                    stroke="#005C87"
                    strokeOpacity={0.3}
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </span>
            <NewActivityDropdownCommon
              style={{width:"350px"}}
            >
              <Dropdown.Toggle>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",fontFamily:"Rubik-Medium"}}>
                  <div>{comparisonFromYear}</div>
                  {this.props.yearList?.length>1?<div>
                    <img
                      alt="image"
                      src="/public/images/teamDownArrow.svg"
                    />
                  </div>:null}
                </div>
              </Dropdown.Toggle>
              {this.props.yearList?.length>1?<Dropdown.Menu>
                {this.props.yearList?.map((list, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={(this.props.yearList.length==index+1)?null:() => this.handleComparisonYear(list, (comparisonToYear<list?comparisonToYear: this.props.yearList[index+1]))}
                  >
                    {list}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>:null}
            </NewActivityDropdownCommon>
          </WrapperYear>
          <div style={{ width:'100%'}}>
            {EngagementEmployeeData.map((item,index)=>(
              <YearDataComparison key={index} background={item.color}>
                <div className="image">
                  <img src={ImageUrl +  item.img}/>
                </div>
                <div className="title">
                  {item.title}
                </div>
                <div className="typeValue">
                  <div className="value">{item.toCount}</div>
                  <div className="type">{item.totalCount}</div>
                </div>
                {item.difference==0?
                  <div className="arrows" style={{justifyContent:"center"}}>
                    <div className={"percentOrange"}>{item.difference}%</div>
                  </div>
                  :<div className="arrows">
                    <div className="arrow">
                      <img style={{marginLeft:'5px'}} src={`${ImageUrl}/admin-recognition/icons/${item.differenceType}.png`}/>
                    </div>
                    {item.differenceType === "plus"?<div className={"percent"}>{item.differenceType === "plus" && "+"}{item.difference}%</div>:<div className={"percentRed"}>{item.differenceType === "plus" && "+"}{item.difference}%</div>}
                  </div>}
                <div className="typeValue">
                  <div className="value">{item.fromCount}</div>
                  <div className="type">{item.totalCount}</div>
                </div>
              </YearDataComparison>))}
          </div>
        </Modal.Body>
      </ComparisonStyledModal>
    );
  };

  manageAnniversariesModal = () => {
    const { isManageAnniversaryModal, manageAnniversary } = this.state;
    return (
      <SeeAllStyledModal
        show={isManageAnniversaryModal}
        onHide={this.handleAnniversaryModal}
        // dialogClassName="modal-90w"
        style={{ top: "30%" }}
      >
        <Modal.Header style={{background:"#005c87"}}>
          <CustomTitle>
            <div>
              <span style={{ textTransform: "capitalize" }}>
                {"Manage Anniversaries"}
              </span>
              <span
                className="closeImage"
                onClick={this.handleAnniversaryModal}
              >
                <img src="/public/images/new_close_icon.png" />
              </span>
            </div>
          </CustomTitle>
        </Modal.Header>
        <Modal.Body style={{ padding:'30px 0px' }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              marginBottom: "30px",
            }}
          >
            <div
              style={{
                marginLeft: "25px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#005c87", fontSize: "16px" }}>
                Birthday
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ToggleButton>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="isBirthday"
                    checked={manageAnniversary.isBirthday === 1 ? true : false}
                    onChange={this.handleToggleBtn}
                  />
                  <span className="slider round"></span>
                </label>
              </ToggleButton>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              borderTop: "1px solid rgba(156, 156, 156, 0.4)",
            }}
          >
            <div
              style={{
                marginLeft: "25px",
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <span style={{ color: "#005c87", fontSize: "16px" }}>
                Work Anniversary
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              <ToggleButton>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="isAnniversary"
                    checked={manageAnniversary.isAnniversary === 1 ? true : false}
                    onChange={this.handleToggleBtn}
                  />
                  <span className="slider round"></span>
                </label>
              </ToggleButton>
            </div>
          </div>
        </Modal.Body>
      </SeeAllStyledModal>
    );
  };

  handleConfirmAnniversaryModal = () => {
    this.setState({
      confirmAnniversary: {
        ...this.state.confirmAnniversary,
        showModal: !this.state.confirmAnniversary.showModal,
      },
    });
  };

  onYesClick = async() => {
    const { companyId, confirmAnniversary, manageAnniversary } = this.state;
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url =
    BASE_URL + PutBirthdayAndAnniversaryCardToggle;
    const payload = {company_id:companyId,show_birthday_card: confirmAnniversary.type === "isBirthday" ? 0 : manageAnniversary.isBirthday ? 1 :0,show_work_anniversary_card:confirmAnniversary.type === "isAnniversary" ? 0 : manageAnniversary.isAnniversary ? 1 : 0}
    await fetch(url, { method: "PUT", headers: { AUTHTOKEN: AuthToken, 'Content-Type': 'application/json'  }, body:JSON.stringify(payload) }).then(
      (res) => {
        res.json().then((data) => {
          if(res.status === 200){
            if(confirmAnniversary.type === "isBirthday"){
              this.setState({ manageAnniversary: {...manageAnniversary,isBirthday:0} });
            }else if(confirmAnniversary.type === "isAnniversary"){
              this.setState({ manageAnniversary: {...manageAnniversary,isAnniversary:0} });
            }
            this.handleConfirmAnniversaryModal();
          }else{
            toast.error(data.data.message);
          }

        }).catch((error)=>{
          toast.error(error.message);
        });
      }
    ).catch((error)=>{
      toast.error(error.message);
    })
  };

  confirmAnniversaryModal = () => {
    const { confirmAnniversary } = this.state;
    return (
      <ConfirmStyledModal
        show={confirmAnniversary.showModal}
        onHide={this.handleConfirmAnniversaryModal}
        // dialogClassName="modal-90w"
        style={{ top: "25%", width: "300px", left: "41%" }}
      >
        <Modal.Body style={{ padding: "25px 0px", width: "300px",boxShadow:"0px 13px 28px rgba(0, 0, 0, 0.3)" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection:'column',
              borderBottom:'1px solid #cdcdcd',
              width:'100%',
              marginBottom:'15px'
            }}
          >
            <div style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', width:'222px', textAlign:'center'}}>
              <span style={{textAlign:'center', color:'#005c87', fontSize:"14px"}}>Are you sure you want to</span>{" "}
              <span style={{ fontFamily: "Rubik-medium", fontWeight: "500", textAlign:'center', color:"#005c87", fontSize:"14px" }}>
                Turn Off the {confirmAnniversary.type === "isBirthday" ?  "Birthday" : confirmAnniversary.type === "isAnniversary" ?  "Work Anniversary" : null} Wishes?
              </span>
            </div>

            <div style={{margin:'15px 0px', display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', width:'255px', textAlign:'center'}}>
              <span style={{color:'#669db7', fontSize:'14px', lineHeight:'20px'}}>Once you off this {confirmAnniversary.type === "isBirthday" ?  "Birthday" : confirmAnniversary.type === "isAnniversary" ?  "Work Anniversary" : null}, then it will be removed for all users in {this.props.companyInfo?.name}. User cannot be able to wish anyone after turning it off.</span>
            </div>
          </div>
          {/* <div style={{borderTop:'1px solid #9C9C9C'}}></div> */}

          <div className="btns" >
            <button className="btn1" onClick={this.onYesClick}>
              Yes
            </button>
            <button className="btn2" onClick={this.handleConfirmAnniversaryModal}>
              No
            </button>
          </div>
        </Modal.Body>
      </ConfirmStyledModal>
    );
  };

  handleActiveTab = (active, tab) => this.setState({activeTab: active}, ()=>{
    this.setState((prev) => ({listOpen: !prev.listOpen, label: tab.label}));
    if(tab.label=="Library"){
      this.redirectToAddCoreValues()
    }
    this.getActiveTab(active)
  });

  renderYearDropdown = (yearList, selectedYearInfo) => (
    <div style={{ display: "flex", float: "right" }}>
      <YearDropdown
        background={"#FFFFFF"}
        refOutside={this.dropdownRef}
        toggleMenu={this.toggleMenu}
        title={
          selectedYearInfo && (
            <div
              className="title"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                color: '#007AB1',
                fontSize: '16px',
                fontFamily: 'Rubik',
                fontWeight:'bold',
                gap:'12px',
              }}
            >
              <div>{selectedYearInfo}</div>{" "}
              <div style={{ marginRight: "5px" }}>
                <svg
                  width="12"
                  height="20"
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    // position: 'relative', top: '9px', right: '35px',
                    transform: this.state.menuIsOpen ? "rotate(90deg)" : "rotate(90deg)",
                  }}
                >
                  <path
                    d="M12 10C12 10.3585 11.8559 10.7169 11.5684 10.9901L2.51663 19.5897C1.94083 20.1368 1.00725 20.1368 0.431681 19.5897C-0.143894 19.0429 -0.143894 18.1561 0.431681 17.609L8.44122 10L0.43196 2.39098C-0.143614 1.84394 -0.143614 0.957264 0.43196 0.410484C1.00753 -0.136826 1.94111 -0.136826 2.51691 0.410484L11.5687 9.00992C11.8563 9.28333 12 9.64172 12 10Z"
                    fill='rgba(0, 122, 177, 1)' /*"#0D4270"*/
                  />
                </svg>
              </div>
            </div>
          )
        }
        labelStyle={{ margin: "5px 10px 0px 0px" }}
        data={yearList}
        onSelect={this.updateDropdown}
        style={{
          background: "#ffff",
          lineHeight: "20px",
          color: "rgba(0, 92, 135, 0.6)",
          padding: "0px",
          border: "1px solid rgba(0, 92, 135, 0.3)",
          borderRadius:'4px',
        }}
      />
    </div>
  );

  render() {
    const {
      employeesOfTheMonthDetails,
      recognitionDetails,
      companyInfo,
      getRecognitionDepartmentDetails,
      getRecognitionLocationDetails,
      recognitionLoading,
      getRecognitionActivityDetails,
      getTopReceiversRecognitionDetails,
      getTopRecognizedRecognitionDetails,
      getCompanyRecognitions,
      employeesOfTheMonthlylyDetails,
      employeesOfTheMonthByQuarterlyDetails,
      history,
      selectedYearInfo,
      yearList,
      getRecognitionForAllDepartmentsDetails,
      getRecognitionForAllLocationsDetails,
      monthlyRecognitionData
    } = this.props;
    const {label} = this.state;
    if (
      _.isNull(recognitionDetails) ||
      _.isUndefined(recognitionDetails) ||
      isEmpty(recognitionDetails) ||
      _.isNull(getRecognitionDepartmentDetails) ||
      _.isUndefined(getRecognitionDepartmentDetails) ||
      _.isNull(getRecognitionLocationDetails) ||
      _.isUndefined(getRecognitionLocationDetails) ||
      recognitionLoading ||
      _.isUndefined(getRecognitionActivityDetails) ||
      _.isNull(getRecognitionActivityDetails)
    ) {
      return <Waiting />;
    }
    const tabs = [
      {
        label: "Analysis",
        component: (
          <CompanyAnalytics
            companyInfo={companyInfo}
            getRecognitionDepartmentDetails={getRecognitionDepartmentDetails}
            getRecognitionLocationDetails={getRecognitionLocationDetails}
            getRecognitionForAllDepartmentsDetails={
              getRecognitionForAllDepartmentsDetails
            }
            getRecognitionForAllLocationsDetails={
              getRecognitionForAllLocationsDetails
            }
            recognitionDetails={recognitionDetails}
            selectedYearInfo={selectedYearInfo}
            yearList={yearList}
          />
        ),
      },
      {
        label: "Activity",
        component: (
          <RecognitionActivity
            getRecognitionActivityDetails={getRecognitionActivityDetails}
            companyInfo={companyInfo}
            topRecognized={getTopRecognizedRecognitionDetails}
            topReceivers={getTopReceiversRecognitionDetails}
            companyRecognitions={getCompanyRecognitions}
            recognitionDetails={recognitionDetails}
            selectedYearInfo={selectedYearInfo}
          />
        ),
      },
      {
        label: "Best Performer",
        component: (
          <EmployeesOfTheMonth
            employeesOfTheMonthDetails={employeesOfTheMonthDetails}
            employeesOfTheMonthlylyDetails={employeesOfTheMonthlylyDetails}
            employeesOfTheMonthByQuarterlyDetails={
              employeesOfTheMonthByQuarterlyDetails
            }
            topEmployees={employeesOfTheMonthDetails}
            topMonthEmployees={employeesOfTheMonthlylyDetails}
            topQuarterEmployees={employeesOfTheMonthByQuarterlyDetails}
            history={history}
            monthlyRecognitionData={monthlyRecognitionData}
          />
        ),
      },
      {
        label: "Anniversaries",
        component: (
          <Anniversaries
            employeesOfTheMonthDetails={employeesOfTheMonthDetails}
            employeesOfTheMonthlylyDetails={employeesOfTheMonthlylyDetails}
            employeesOfTheMonthByQuarterlyDetails={
              employeesOfTheMonthByQuarterlyDetails
            }
            topEmployees={employeesOfTheMonthDetails}
            topMonthEmployees={employeesOfTheMonthlylyDetails}
            topQuarterEmployees={employeesOfTheMonthByQuarterlyDetails}
            history={history}
            birthdayList={this.state.birthdayList}
            anniversaryList={this.state.anniversaryList}
            fetchBirthdayList={this.fetchBirthdayList}
            fetchAnniversaryList={this.fetchAnniversaryList}
            companyId={this.state.companyId}
            exportToExcel={this.exportToExcel}
          />
        ),
      },
      {
        label: "Library"
      },
    ];

    return (
      <React.Fragment>
        <RecognitionSection>
          <div className="recognition-wrapper">
            <DashboardLayout
              margin={"0px 0px 0px 0px"}
              padding={"0px 0px 0px 0px"}
            >
              <HeaderContainer padding="1" style={{borderRadius:'6px', padding:'8px'}}>
                <HeaderTitle>
                  {
                    tabs.map((tab, index) => (
                      <BuddyTab
                        key={index}
                        width="1"
                        padding="1"
                        onClick={() => this.handleActiveTab(index, tab)}
                        margin={"0px 15px 0px 15px"}
                        active={label == tab.label ? true : false}
                        style={{ background: label == tab.label ? 'rgba(0, 122, 177, 1)' : "unset", color: label == tab.label ? "#fff" : "rgba(0, 122, 177, 1)" }}
                      >
                        {tab.label}
                      </BuddyTab>
                    ))
                  }
                </HeaderTitle>
                <div style={{ display: "flex", alignItems: "center" }}>

                  <HoverButton
                    title={"Recognition Comparison"}
                    svgPath={<ComparisonIcon />}
                    onClick={this.handleComparisonModal}
                    fillOpacity="1"
                  />

                  <HoverButton
                    title={"Manage Anniversaries"}
                    svgPath={<ManageAnniversaryIcon />}
                    onClick={this.handleAnniversaryModal}
                    // width={"22"} height={"19"}
                    fillOpacity="1"
                  />
                  {!(this.state.currentTab === 2 || this.state.currentTab === 3) ? (
                    <div
                      className="year-dropdown"
                      style={{ display: "flex", alignItems: "center", marginLeft:'15px', marginRight:'-15px'}}
                    >
                      {this.renderYearDropdown(yearList, selectedYearInfo)}
                    </div>
                  ) : null}
                </div>
                {/* <Buttons onClick={this.redirectToAddCoreValues}>
                  <img src="/public/images/hand.svg" />
                  <p>Manage Core value</p>
                </Buttons> */}
              </HeaderContainer>
            </DashboardLayout>
            {/* {this.showRecognitionDetails(recognitionDetails)} */}
            <div style={{ padding: "0px 0px 0px 0px" }}>
              <RecognitionCustomDropDown
                tabs={tabs}
                history={this.props.history}
                recognitionDropDown={1}
                companyId={this.state.companyId}
                getActiveTab={this.getActiveTab}
                activeTab = {this.state.activeTab}
              />
            </div>
          </div>
        </RecognitionSection>
        {this.state.isManageAnniversaryModal && this.manageAnniversariesModal()}
        {this.state.isComparisonModal && this.comparisonModal()}
        

        {this.state.confirmAnniversary.showModal &&
          this.confirmAnniversaryModal()}
      </React.Fragment>
    );
  }
}
Recognition.defaultProps = {
  companyInfo: { id: 1, name: "" },
};
Recognition.propTypes = {
  companies: PropTypes.array,
  companyInfo: PropTypes.object.isRequired,
  recognitionDetails: PropTypes.array,
  employeesOfTheMonthDetails: PropTypes.object,
  history: PropTypes.object,
  fetchRecognition: PropTypes.func,
  fetchChampionAPI: PropTypes.func,
  mostSocialUsersDetails: PropTypes.object,
  getRecognitionDepartmentDetails: PropTypes.array,
  getTopReceiversRecognitionDetails: PropTypes.array,
  getTopRecognizedRecognitionDetails: PropTypes.array,
  getRecognitionLocationDetails: PropTypes.array,
  recognitionLoading: PropTypes.bool,
  getCompanyRecognitions: PropTypes.array,
  challengeOverviewData: PropTypes.array,
  employeeCount: PropTypes.number,
  fetchRecognitionActivityDetails: PropTypes.func,
  getRecognitionActivityDetails: PropTypes.array,
  employeesOfTheMonthlylyDetails: PropTypes.object,
  employeesOfTheMonthByQuarterlyDetails: PropTypes.object,
  setSelectedYear: PropTypes.func,
  selectedYearInfo: PropTypes.number,
  yearList: PropTypes.array,
  getRecognitionForAllDepartments: PropTypes.func,
  getRecognitionForAllLocations: PropTypes.func,
  getRecognitionForAllDepartmentsDetails: PropTypes.array,
  getRecognitionForAllLocationsDetails: PropTypes.array,
  role: PropTypes.string,
  fetchEmployeeSeprateRecognitionMonthly: PropTypes.func,
  monthlyRecognitionData: PropTypes.array
};

const mapStateToProps = (state) => ({
  recognitionDetails: state.companyDashboard.recognitionDetails,
  employeesOfTheMonthDetails: state.companyDashboard.employeesOfTheMonthDetails,
  mostSocialUsersDetails: state.companyDashboard.mostSocialUsersDetails,
  getRecognitionActivityDetails:
    state.companyDashboard.getRecognitionActivityDetails,
  getTopRecognizedRecognitionDetails:
    state.companyDashboard.getTopRecognizedRecognitionDetails,
  getTopReceiversRecognitionDetails:
    state.companyDashboard.getTopReceiversRecognitionDetails,
  getRecognitionDepartmentDetails:
    state.companyDashboard.getRecognitionDepartmentDetails,
  getRecognitionLocationDetails:
    state.companyDashboard.getRecognitionLocationDetails,
  recognitionLoading: state.companyDashboard.recognitionLoading,
  getCompanyRecognitions: state.companyDashboard.getCompanyRecognitions,
  challengeOverviewData: state.challengesAdmin.challengeOverviewData,
  employeesOfTheMonthlylyDetails:
    state.companyDashboard.employeesOfTheMonthlylyDetails,
  employeesOfTheMonthByQuarterlyDetails:
    state.companyDashboard.employeesOfTheMonthByQuarterlyDetails,
  selectedYearInfo: state.companyDashboard.selectedYearInfo,
  getRecognitionForAllDepartmentsDetails:
    state.companyDashboard.getRecognitionForAllDepartmentsDetails,
  getRecognitionForAllLocationsDetails:
    state.companyDashboard.getRecognitionForAllLocationsDetails,
  role: state.auth.role,
  monthlyRecognitionData:state.companyDashboard.monthlyRecognitionData

});

const mapDispatchToProps = (dispatch) => ({
  fetchRecognition: (id, year) => dispatch(fetchRecognitionTabApi(id, year)),
  fetchRecognitionActivityDetails: (id, year) =>
    dispatch(getRecognitionActivityDetails(id, year)),
  setSelectedYear: (year) => dispatch(setSelectedYear(year)),
  getRecognitionForAllDepartments: (id, year) =>
    dispatch(getRecognitionForAllDepartments(id, year)),
  getRecognitionForAllLocations: (id, year) =>
    dispatch(getRecognitionForAllLocations(id, year)),
  fetchEmployeeSeprateRecognitionMonthly: (id, year) =>
    dispatch(fetchEmployeeSeprateRecognitionMonthly(id, year)),  
});
export default connect(mapStateToProps, mapDispatchToProps)(Recognition);
